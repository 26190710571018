<template>
    <Head :title="`Edit - ${vendorSalesTax.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Tenant Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.vendor-sales-taxes.index')" class="breadcrumb-link">Vendor Invoice Sales Taxes</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ vendorSalesTax.name }}</span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <div class="container mx-auto">
        <div class="my-4">
            <form id="edit-vendor-sales-tax-form" class="w-full">
                <div class="grid grid-cols-2 gap-6">
                    <template v-if="hasAccountingIntegration">
                        <toggle-switch-input v-model="form.post_to_accounting" label="Itemize When Posting?" />

                        <select-input
                            v-if="form.post_to_accounting"
                            v-model="form.gl_code"
                            label="GL Account"
                            :errors="errors.gl_code"
                            mark-as-required
                        >
                            <option v-for="account in glAccounts" :key="account.id" :value="account.external_accounting_id">{{ account.name }}</option>
                        </select-input>
                    </template>

                    <!-- To be used in sales taxes v2
                    <select-input v-model="form.country" label="Country" :errors="errors.country" mark-as-required>
                        <option value="United States">United States</option>
                        <option value="Canada">Canada</option>
                    </select-input>

                    <select-input v-if="form.country" v-model="form.state_province" label="State/Province" :errors="errors.state_province" mark-as-required>
                        <option v-if="form.country === 'United States'" v-for="state in states">{{ state }}</option>
                        <option v-if="form.country === 'Canada'" v-for="province in provinces" >{{ province }}</option>
                    </select-input>
                    <select-input v-else label="State/Province" v-model="form.state_province" :errors="errors.state_province" mark-as-required>
                        <option disabled value="null">Please Select A Country First</option>
                    </select-input>

                    <select-input v-if="form.country" v-model="form.vendor_sales_tax_type_id" label="Type" :errors="errors.vendor_sales_tax_type_id" mark-as-required>
                        <option v-for="type in typesByCountry[form.country]" :value="type.id">{{ type.name }}</option>
                    </select-input>
                    <select-input v-else label="Type" v-model="form.vendor_sales_tax_type_id" :errors="errors.vendor_sales_tax_type_id" mark-as-required>
                        <option disabled value="null">Please Select A Country First</option>
                    </select-input>

                    <text-input v-model="form.name" label="Name" :errors="errors.name" mark-as-required/>

                    <text-input v-model="form.external_reference_id" label="External Reference Id" :errors="errors.external_reference_id" />

                    <toggle-switch-input v-model="form.applied_by_default" label="Apply to Locations by Default" />

                    <select-input v-model="form.rule_mode" label="Rule Mode" :errors="errors.rule_mode">
                        <option v-for="ruleMode in ruleModesList">{{ ruleMode }}</option>
                    </select-input>
                    -->
                </div>

                <div class="mt-6">
                    <inertia-link :href="$route('tenant-settings.vendor-sales-taxes.show', [vendorSalesTax.id])" class="btn btn-gray mr-4">
                        Cancel
                    </inertia-link>
                    <button class="btn btn-blue" @click.prevent="updateVendorSalesTax">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>

    <!-- Rules To be used in sales taxes v2
    <div class="space-y-6 lg:col-start-1 lg:col-span-3">
        <section aria-labelledby="tax-rules-title">
            <div class="bg-white shadow sm:rounded-lg">
                <div class="flex flex-wrap justify-between">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="tax-rules-title" class="text-lg leading-6 font-medium text-gray-900">Tax Rules</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Placeholder Text.</p>
                    </div>

                    <div v-if="vendorSalesTax.vendorSalesTaxRules.length > 0" class="px-4 py-5 sm:px-6">
                        <inertia-link :href="$route('tenant-settings.vendor-sales-tax-rules.create', [vendorSalesTax.id])" class="btn btn-blue">New</inertia-link>
                    </div>
                </div>

                <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div v-if="vendorSalesTax.vendorSalesTaxRules.length" class="flex flex-col">
                        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Line Item Charge</th>
                                        <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Applied to Allocations</th>
                                        <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span class="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                    </thead>

                                    <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr v-for="taxRule in taxRules" :key="taxRule.id">
                                        <td class="whitespace-nowrap px-2 py-2 text-sm font-medium">{{ taxRule.display_name }}</td>
                                        <td class="">{{ taxRule.apply_to_allocations }}</td>
                                        <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            <button @click.prevent="removeTaxRule(taxRule.id)" class="link">
                                                Delete <span class="sr-only">, {{ taxRule.display_name }}</span>
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div v-else class="text-center">
                        <icon name="file-signature" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                        <h3 class="mt-2 text-sm font-medium text-gray-900">No Tax Rules</h3>
                        <p class="mt-1 text-sm text-gray-500">Create a new tax rule.</p>
                        <div class="mt-6">
                            <inertia-link :href="$route('tenant-settings.vendor-sales-tax-rules.create', [vendorSalesTax.id])" class="btn btn-blue">
                                <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                                </svg>
                                New Tax Rule
                            </inertia-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    -->
</template>

<script setup>
import {router, usePage} from '@inertiajs/vue3';
import { ref, reactive, inject, onMounted, watch } from 'vue';

// Components
import { Head } from '@inertiajs/vue3';
import Icon from '@/Shared/Icon.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import { states, provinces } from '@/Shared/StatesAndProvinces.js';
import TextInput from '@/Shared/TextInput.vue';
import TextareaInput from '@/Shared/TextareaInput.vue';
import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';

// Inject
const route = inject('route');
const page = usePage();

// Props
const props = defineProps({
    errors: {
        type: Object,
        default: () => ({})
    },
    vendorSalesTax: {
        type: Object,
        required: true
    },
    glAccounts: {
        type: Object,
        required: false
    },
    /* To be used in sales taxes v2
    typesByCountry: {
        type: Object,
        required: true
    },
    ruleModesList: {
        type: Array,
        required: true
    },
    taxRules: {
        type: Object,
        required: true
    },
    */
});

// State
const form = reactive({
    ...props.vendorSalesTax,
    post_to_accounting: props.vendorSalesTax.gl_code !== null,
});
const mounted = ref(false);

const hasAccountingIntegration = page.props.permissions.accessQuickbooksOnlineIntegration || page.props.permissions.accessQuickbooksDesktopIntegration;

// Mount
onMounted(() => {
    mounted.value = true;
});

// Watchers
/* To be used in sales taxes v2
watch(
    () => form.country,
    () => {
        form.state_province = null;
        form.type = null;
    }
);
*/


// Methods
function updateVendorSalesTax() {
    if (! form.post_to_accounting) {
        form.gl_code = null;
    }

    router.put(route('tenant-settings.vendor-sales-taxes.update', props.vendorSalesTax.id), form);
}

/* To be used in sales taxes v2
function removeTaxRule(taxRuleId) {
    router.delete(route('tenant-settings.vendor-sales-tax-rules.destroy', taxRuleId));
}
*/
</script>