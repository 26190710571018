<template>
    <Head :title="`Vendor Sales Tax - ${vendorSalesTax.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Tenant Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.vendor-sales-taxes.index')" class="breadcrumb-link">Vendor Invoice Sales Taxes</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>{{ vendorSalesTax.name }}</span>
        </nav>
    </Teleport>

    <div class="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div class="flex items-center space-x-5">
            <div class="flex-shrink-0">
                <icon name="map-marker-alt" class="h-14 w-14" />
            </div>

            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    {{ props.vendorSalesTax.name }}
                    <span v-if="props.vendorSalesTax.external_reference_id" class="text-lg font-semi-bold text-orange-300">{{ props.vendorSalesTax.external_reference_id }}</span>
                </h1>
            </div>
        </div>

        <div v-if="hasAccountingIntegration" class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <inertia-link :href="$route('tenant-settings.vendor-sales-taxes.edit', [vendorSalesTax.id])" class="btn btn-gray">Edit</inertia-link>
        </div>
    </div>

    <div class="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div class="space-y-6 lg:col-start-1 lg:col-span-2">
            <!-- Description list-->
            <section aria-labelledby="applicant-information-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" class="text-lg leading-6 font-medium text-gray-900">Record Information</h2>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">Vendor sales tax details</p>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Country</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ props.vendorSalesTax.country }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">{{ state_provinceLabel }}</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ props.vendorSalesTax.state_province }}</dd>
                            </div>

                            <div v-if="props.vendorSalesTax.vendorSalesTaxType" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Sales Tax Type</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ props.vendorSalesTax.vendorSalesTaxType.name }}</dd>
                            </div>

                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Rule mode</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ props.vendorSalesTax.rule_mode }}</dd>
                            </div>

                            <div v-if="hasAccountingIntegration" class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Itemize When Posting</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ itemizeWhenPostingDecoratedName }}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        </div>

        <!-- Rules !-->
        <div class="space-y-6 lg:col-start-1 lg:col-span-3">
            <section aria-labelledby="tax-rules-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="flex flex-wrap justify-between">
                        <div class="px-4 py-5 sm:px-6">
                            <h2 id="tax-rules-title" class="text-lg leading-6 font-medium text-gray-900">Tax Rules</h2>
                            <p class="mt-1 max-w-2xl text-sm text-gray-500"> </p>
                        </div>
                    </div>

                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div v-if="vendorSalesTax.vendorSalesTaxRules.length" class="flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Line Item Charge</th>
                                                <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">Applied to Allocations</th>
                                            </tr>
                                        </thead>

                                        <tbody class="divide-y divide-gray-200 bg-white">
                                            <tr v-for="taxRule in props.vendorSalesTax.vendorSalesTaxRules" :key="taxRule.id">
                                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium">{{ taxRule.display_name }}</td>
                                                <td class="">{{ taxRule.apply_to_allocations ? 'Yes' : 'No' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div v-else class="text-center">
                            <icon name="file-signature" class="mx-auto h-12 w-12 text-gray-400 fill-current" />

                            <h3 class="mt-2 text-sm font-medium text-gray-900">No Tax Rules</h3>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue';

// Components
import {Head, usePage} from '@inertiajs/vue3';
import Icon from '@/Shared/Icon.vue';

// Inject
const route = inject('route');
const page = usePage();

// Props
const props = defineProps({
    errors: {
        type: Object,
        default: () => ({})
    },
    vendorSalesTax: {
        type: Object,
        required: true
    },
});

// State
const mounted = ref(false);

const state_provinceLabel = props.vendorSalesTax.country === 'Canada' ? 'Province' : 'State';
const hasAccountingIntegration = page.props.permissions.accessQuickbooksOnlineIntegration || page.props.permissions.accessQuickbooksDesktopIntegration;
const itemizeWhenPostingDecoratedName = props.vendorSalesTax.gl_code ?
    `GL Account: ${props.vendorSalesTax.gl_code}` :
    'No';

// Mount
onMounted(() => {
    mounted.value = true;
});

</script>